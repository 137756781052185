import { PopupController } from 'types/Common';
import {
  Chip, Dialog, DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow, TextField,
} from '@mui/material';
import { t, Trans } from '@lingui/macro';
import { InputNumber } from 'components';
import { LoadingButton } from '@mui/lab';
import { ActionConfirmPopup } from './index';
import { useRef, useState } from 'react';
import { useMutation } from 'react-query';
import { enterpriseSetupService, queryClient } from 'services';
import { useSnackbar } from 'notistack';
import { Controller, useForm } from 'react-hook-form';

type PopupProps = PopupController & {
  enterpriseId: string;
  onError?: () => void;
};

const WaitingPaymentEditServiceFeePopup = ({ enterpriseId, onClose, onSuccess, onError }: PopupProps) => {
  const { enqueueSnackbar } = useSnackbar();
  const { control, handleSubmit, reset, formState } = useForm({ mode: 'onChange' });
  const inputRef = useRef<HTMLInputElement>(null);
  const [fileSelected, setFileSelected] = useState<File | null>();

  const [actionConfirmPopup, setActionConfirmPopup] = useState(false);

  const { mutate: submitEditServiceFee, isLoading } = useMutation(enterpriseSetupService.editServiceFee, {
    onSuccess: () => {
      enqueueSnackbar(t`Successful`);
      setActionConfirmPopup(false);
      clearFormData();
      queryClient.invalidateQueries('enterpriseSetupService.getEnterpriseWaitingPaymentData');
      if (onSuccess) {
        onSuccess();
      }
    },
    onError: () => {
      setActionConfirmPopup(false);
      if (onError) {
        onError();
      }
    },
  });

  const handleChangeFiles = (event: React.ChangeEvent<HTMLInputElement>) => {
    const fileList = event.target.files;
    if (!fileList) return;
    setFileSelected(fileList[0]);
  };

  const handleClickSubmit = () => {
    handleSubmit((values) => {
      if (fileSelected) {
        const formData = new FormData();
        formData.append('fee_file', fileSelected, fileSelected.name);
        formData.append('transaction_code_column_index', values.transactionCodeColumnIndex);
        formData.append('employee_name_column_index', values.employeeNameColumnIndex);
        formData.append('before_tax_fee_column_index', values.beforeTaxFeeColumnIndex);
        if (values.afterTaxFeeColumnIndex)
          formData.append('after_tax_fee_column_index', values.afterTaxFeeColumnIndex);
        formData.append('data_start_row_index', values.dataStartRowIndex);
        submitEditServiceFee({ enterpriseId, formData });
      } else {
        enqueueSnackbar('Please select file', { variant: 'warning' });
      }
    })();
  };

  const clearFormData = () => {
    setFileSelected(null);
    if (inputRef.current?.value) {
      inputRef.current.value = '';
    }
    reset({
      'transactionCodeColumnIndex': '',
      'employeeNameColumnIndex': '',
      'beforeTaxFeeColumnIndex': '',
      'afterTaxFeeColumnIndex': '',
      'dataStartRowIndex': '',
    });
  };

  return (
    <>
      <DialogTitle>
        <Trans>Edit Service Fee</Trans>
      </DialogTitle>

      <DialogContent>
        <Grid container columnSpacing={2} rowSpacing={3}>
          <Grid item sm={12}>
            <TableContainer component={Paper} variant="outlined">
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell className="border-r">
                      <Trans>Transaction ID</Trans>
                      <br />
                      <small>(<Trans>Column Index Note</Trans>)</small>
                    </TableCell>
                    <TableCell className="border-r">
                      <Controller
                        name="transactionCodeColumnIndex"
                        // defaultValue={'A'}
                        control={control}
                        rules={{ required: t`Field value is required` }}
                        render={({ field, fieldState: { invalid, error } }) => (
                          <TextField
                            {...field}
                            fullWidth
                            label={t`Column Index`}
                            required={true}
                            error={invalid}
                            helperText={error?.message}
                          />
                        )}
                      />

                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className="border-r">
                      <Trans>Employee Name</Trans>
                      <br />
                      <small>(<Trans>Column Index Note</Trans>)</small>
                    </TableCell>
                    <TableCell className="border-r">
                      <Controller
                        name="employeeNameColumnIndex"
                        // defaultValue={'A'}
                        control={control}
                        rules={{ required: t`Field value is required` }}
                        render={({ field, fieldState: { invalid, error } }) => (
                          <TextField
                            {...field}
                            fullWidth
                            label={t`Column Index`}
                            required={true}
                            error={invalid}
                            helperText={error?.message}
                          />
                        )}
                      />

                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className="border-r">
                      <Trans>Service Fee Before Tax</Trans>
                      <br />
                      <small>(<Trans>Column Index Note</Trans>)</small>
                    </TableCell>
                    <TableCell className="border-r">
                      <Controller
                        name="beforeTaxFeeColumnIndex"
                        // defaultValue={'A'}
                        control={control}
                        rules={{ required: t`Field value is required` }}
                        render={({ field, fieldState: { invalid, error } }) => (
                          <TextField
                            {...field}
                            fullWidth
                            label={t`Column Index`}
                            required={true}
                            error={invalid}
                            helperText={error?.message}
                          />
                        )}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className="border-r">
                      <Trans>Service Fee After Tax</Trans>
                      <br />
                      <small>(<Trans>Column Index Note</Trans>)</small>
                    </TableCell>
                    <TableCell className="border-r">
                      <Controller
                        name="afterTaxFeeColumnIndex"
                        // defaultValue={0}
                        control={control}
                        render={({ field, fieldState: { invalid, error } }) => (
                          <TextField
                            {...field}
                            fullWidth
                            label={t`Column Index`}
                            error={invalid}
                            helperText={error?.message}
                          />
                        )}
                      />
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell className="border-r">
                      <Trans>Data Start</Trans>
                      <br />
                      <small>(<Trans>Row Index Note</Trans>)</small>
                    </TableCell>
                    <TableCell className="border-r">
                      <Controller
                        name="dataStartRowIndex"
                        // defaultValue={1}
                        control={control}
                        rules={{ required: t`Field value is required` }}
                        render={({ field, fieldState: { invalid, error } }) => (
                          <TextField
                            {...field}
                            fullWidth
                            label={t`Row Index`}
                            required={true}
                            error={invalid}
                            helperText={error?.message}
                            InputProps={{
                              inputComponent: InputNumber as any,
                              inputProps: { thousandSeparator: '.', decimalSeparator: ',' },
                            }}
                          />
                        )}
                      />
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell className="border-r"></TableCell>
                    <TableCell className="border-r">
                      <input ref={inputRef} type="file" hidden onChange={handleChangeFiles}
                             accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" />
                      <div className="space-x-3">
                        <LoadingButton variant="outlined" size="small" onClick={() => inputRef.current?.click()}>
                          <Trans>Choose Files</Trans>
                        </LoadingButton>
                        {fileSelected && (
                          <Chip
                            key={fileSelected?.name}
                            variant="outlined"
                            label={fileSelected?.name}
                            onDelete={() => {
                              setFileSelected(null);
                              if (inputRef.current?.value) {
                                inputRef.current.value = '';
                              }
                            }}
                          />
                        )}
                      </div>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>

        </Grid>
      </DialogContent>

      <DialogActions>
        <div className="flex items-center justify-between w-full">
          <LoadingButton variant="outlined" onClick={onClose}>
            <Trans>Cancel</Trans>
          </LoadingButton>
          <LoadingButton
            variant="contained"
            disabled={!formState.isValid || fileSelected == null || isLoading}
            onClick={() => setActionConfirmPopup(true)}
          >
            <Trans>Confirm</Trans>
          </LoadingButton>
        </div>
      </DialogActions>

      <Dialog fullWidth maxWidth="sm" open={actionConfirmPopup} onClose={() => setActionConfirmPopup(false)}>
        <ActionConfirmPopup
          isLoading={isLoading}
          handleAction={handleClickSubmit}
          onClose={() => setActionConfirmPopup(false)}
        />
      </Dialog>
      <div className="hidden">
        <Trans>duplicate column</Trans>
      </div>
    </>
  );
};

export default WaitingPaymentEditServiceFeePopup;