import { t } from '@lingui/macro';

type ErrorType = {
  [key: number]: string;
};

export const ERROR_TYPE: ErrorType = {
  // 400: t`Bad Request`,
  409: t`Conflict`,
};

export const getErrorMessage = (message: string) => {
  if (message) {
    const MAP_ERROR: { [char: string]: string } = {
      'Invalid Data': t`Invalid Data`,
      'Invalid data': t`Invalid Data`,
      'Data is invalid': t`Invalid Data`,
      'invalid content type': t`Invalid Data`,
      'Invalid amount': t`Invalid Amount`,
      'Invalid Amount': t`Invalid Amount`,
      'Invalid payment date': t`Invalid payment date`,
      'Not found user': t`Not found user`,
      'user not exist': t`User not exist`,
      'User not exist': t`User not exist`,
      'status must be active or inactive': t`Status must be active or inactive`,
      'User is existed': t`User is existed`,
      'No Email': t`No Email`,
      'No card id': t`No card id`,
      'Can not delete active user': t`Can not delete active user`,
      'user has not been assigned': t`user has not been assigned`,
      'confirm password is not match': t`confirm password is not match`,
      'user with email {0} is existed': t`user with email {0} is existed`,
      'user {0} no longer exist': t`User {0} no longer exist`,
      'Email is required': t`Email is required`,
      'Username is required': t`Username is required`,
      'Can not create sub master user': t`Can not create sub master user`,
      'Can not create master user': t`Can not create master user`,
      'Exceeded payment limit': t`Exceeded payment limit`,
      'Your account has not eligible to request salary advance': t`Your account has not eligible to request salary advance`,
      'Not employee': t`Not employee`,
      'Expired OTP': t`Expired OTP`,
      'Invalid OTP': t`Invalid OTP`,
      'Invalid status': t`Invalid status`,
      'Status is invalid': t`Invalid status`,
      'invalid state': t`Invalid status`,
      'Not enough enterprise condition setting for trial': t`Not enough enterprise condition setting for trial`,
      'Invalid promotion': t`Invalid promotion`,
      'Promotion is invalid': t`Invalid promotion`,
      'No employee labor in this cycle': t`No employee labor in this cycle`,
      'No available labor': t`No available labor`,
      'No average salary': t`No average salary`,
      'No payroll advance setting': t`No payroll advance setting`,
      'No working block config': t`No working block config`,
      'No working block for this employee': t`No working block for this employee`,
      'No employee in enterprise': t`No employee in enterprise`,
      'Applied date invalid': t`Applied date invalid`,
      'Closing date invalid': t`Closing date invalid`,
      'Starting date invalid': t`Starting date invalid`,
      'files is required': t`files is required`,
      'Team has activating account': t`Team has activating account`,
      'No exits employee in enterprise': t`No exits employee in enterprise`,
      'This method is waiting for confirmation': t`This method is waiting for confirmation`,
      'Invalid total labors': t`Invalid total labors`,
      'No configuration for working block': t`No configuration for working block`,
      'No working block': t`No working block`,
      'Not found employee of this enterprise': t`Not found employee of this enterprise`,
      'stop_working_date is invalid, correct format is DD/MM/YYYY': t`stop_working_date is invalid, correct format is DD/MM/YYYY`,
      'Salary payment date is invalid, correct format is positive integer 1-31': t`Salary payment date is invalid, correct format is positive integer 1-31`,
      'Hour of labor is invalid, correct format is positive integer': t`Hour of labor is invalid, correct format is positive integer`,
      'Wage hourly is invalid, correct format is positive integer': t`Wage hourly is invalid, correct format is positive integer`,
      'T-1 salary is invalid, correct format is positive integer': t`T-1 salary is invalid, correct format is positive integer`,
      'T-2 salary is invalid, correct format is positive integer': t`T-2 salary is invalid, correct format is positive integer`,
      'T-3 salary is invalid, correct format is positive integer': t`T-3 salary is invalid, correct format is positive integer`,
      'start_working_date is invalid, correct format is DD/MM/YYYY': t`start_working_date is invalid, correct format is DD/MM/YYYY`,
      'missing meeting date': t`Missing meeting date`,
      'Row %d has error: %s': t`Row {0} has error: {1}`,
      'Staff ID has been existed in company': t`Staff ID has been existed in company`,
      'Empty note': t`Empty note`,
      'Empty deadline': t`Empty deadline`,
      'you cannot execute this action': t`You cannot execute this action`,
      'invalid enterprise id': t`Invalid enterprise id`,
      'invalid employee id': t`Invalid employee id`,
      'invalid contract id': t`Invalid contract id`,
      'There are no appraisals before': t`There are no appraisals before`,
      'invalid signature': t`Invalid signature`,
      'Contract has been rejected': t`Contract has been rejected`,
      'Contract cannot be rejected when its status is \'COMPLETED\'': t`Contract cannot be rejected when its status is \'COMPLETED\'`,
      'Data is invalid, at least 3 rows': t`Data is invalid, at least 3 rows`,
      'Guarantee is processing': t`Guarantee is processing`,
      'No completed master contract': t`No completed master contract`,
      'Otp is required field': t`Otp is required field`,
      'Not found transaction': t`Not found transaction`,
      'Not found enterprise': t`Not found enterprise`,
      'The employee is not qualified': t`The employee is not qualified`,
      'No employee codes are existed': t`No employee codes are existed`,
      'No employees are existed in this enterprise': t`No employees are existed in this enterprise`,
      'Exist approved transaction settlement record': t`Exist approved transaction settlement record`,
      'user no longer exist': t`User no longer exist`,
      'ContactEmail invalid' : t`ContactEmail invalid`,
      'The information on the upload file is empty' : t`The information on the upload file is empty`,
      'You already had meeting with other client in this time':t`You already had meeting with other client in this time`,
      'Incorrect import data': t`Incorrect import data`
    };
    return MAP_ERROR[message] ?? message;
  }
  return '';
};